import React, { useState, useEffect, useContext } from "react"
import { graphql } from "gatsby"

import "../../../styles/pages/glasses.scss"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import GlassesHeading from "../../../components/GlassesHeading"
import Breadcrumbs from "../../../components/Breadcrumbs"
import GlassesFilter from "../../../components/GlassesFilter"
import GlassesList from "../../../components/GlassesList"
import ProductBanner from "../../../components/ProductBanner"
import { useDebounce, useGlasses } from "../../../services/hooks"
import CartContext from "../../../context/cartContext"

const Glasses = ({ data, location }) => {
  const hubbleFrames = data.hubbleapi.hubbleFrameProducts.filter(
    ({ collectionString }) => {
      const collectionArray = collectionString.split(",")

      return (
        collectionArray.includes("Hubble") &&
        !collectionArray.includes("Modern")
      )
    }
  )

  const right_side_text =
    "At Hubble, we’ve broken down the best eyeglasses for each face shape. Round and oval frames make great picks, as they can help soften edges while complementing a square face’s strong jawline. A notoriously hard to pull off style, those with square faces can pull off this trendy look with ease.<br><br>No matter what you choose, you can’t go wrong with selecting glasses for square faces from Hubble. Plus, every pair of our glasses has impact-resistant lenses, durable barrel hinges, and padded temple tips for extra cush. Look good, see better—and feel great."
  const list_items = [
    "Premium, natural fiber acetate frames that are stronger, more durable, and last longer than plastic frames.",
    "Anti-reflective, anti-glare, anti-scratch, hydrophobic, and oleophobic coatings.",
    "Optional blue-light filtering.",
    "Hand-assembled and made to order glasses for square shaped faces.",
  ]

  const { setHeaderColor } = useContext(CartContext)

  const defaultFilter = {
    price: [],
    width: ["square"],
    shape: [],
    material: [],
    color: [],
    gender: [],
  }

  const params = new URLSearchParams(location.search)

  params.forEach((value, key) => {
    defaultFilter[key] = value.split(",")
  })

  const [filter, setFilter] = useState(defaultFilter)

  const debouncedFilter = useDebounce(filter, 500)

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    params.forEach((value, key) => {
      defaultFilter[key] = value.split(",")
    })

    setFilter(defaultFilter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    if (!debouncedFilter) return

    // navigateWithFilters(location.pathname, debouncedFilter)
  }, [debouncedFilter])

  useEffect(() => {
    setHeaderColor("orange")

    return () => {
      setHeaderColor("black")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortedGlasses = useGlasses(hubbleFrames, debouncedFilter)

  const schemaMarkup = [
      {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/shop-by-face-shape/glasses-square-face-shape",
      "@type": "webpage",
      "name": "Shop Glasses and Sunglasses for Square Face Shapes | Hubble",
      "description": "Shop the best glasses and sunglasses for square face shapes. Explore our selection of glasses that flatter a square face shape and find your new favorite pair of frames today!",
      "publisher": {
      "@type": "Organization",
      "name": "Hubble Contacts"
      }
      }
  ]

  return (
    <Layout className="glasses">
      <SEO
        title="Shop Glasses for Square Face Shapes"
        description="Shop the best glasses for square face shapes. We've picked out a variety of glasses that flatter square face shapes. Find your new pair of glasses for square face shapes with Hubble today!"
        schemaMarkup={schemaMarkup}
      />
      <GlassesHeading
        color="orange"
        title="Shop Glasses for a Square Face Shape"
        description="Get glasses from Hubble that fit everything about you. Find the perfect pair of glasses for square faces, starting at just $49.99"
      />

      <div className="glasses-container">
        <div className="grid-section">
          <Breadcrumbs
            color="orange"
            links={[{ to: "/", label: "Homepage" }, { label: "Eyeglasses" }]}
          />
          <GlassesFilter
            type="glasses"
            glassesLength={sortedGlasses.length}
            filter={filter}
            setFilter={setFilter}
          />
          <GlassesList
            type="glasses"
            glasses={sortedGlasses}
            color={filter.color}
            gender={filter.gender[0]}
          />
          <ProductBanner
            title={"Square Face Shape Glasses from Hubble"}
            right_side_text={right_side_text}
            list_items={list_items}
            optional_image="Hubble-Glasses-PCP-Inset-Lifestyle-Image.jpg"
            optional_image_alt_text="Woman wearing Hubble eyeglasses while enjoing time at home"
            color="orange"
          />
        </div>
      </div>
    </Layout>
  )
}

export default Glasses

export const query = graphql`
  query {
    hubbleapi {
      hubbleFrameProducts {
        title
        handle
        shopifyProductId
        description
        collectionString
        glassesBasePrice
        sunglassesBasePrice
        sunglassesAvailable
        productGender
        maleHeaderImage1
        maleHeaderImage2
        size
        shape
        femaleHeaderImage1
        femaleHeaderImage2
        productImage
        material
        materialFilter
        productClothImage
        sortOrder
        relatedFrames {
          edges {
            node {
              title
              productImage
              hubbleFrameVariants {
                title
                color
                colorFilter
                heroColor
                productImage1
                productImage2
                sunglassesWideImage1
                sunglassesWideImage2
                sunglassesWideImage3
                sunglassesProductImage1
                sunglassesProductImage2
                sunglassesProductImage3
                sunglassesProductImage4
              }
            }
          }
        }
        hubbleFrameVariants {
          title
          shopifyVariantId
          color
          colorFilter
          sku
          heroColor
          availableForSale
          productImage1
          productImage2
          productImage3
          sunglassesWideImage1
          sunglassesWideImage2
          sunglassesWideImage3
          sunglassesProductImage1
          sunglassesProductImage2
          sunglassesProductImage3
          sunglassesProductImage4
        }
      }
    }
  }
`
